import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

class Footer extends Component {
  getYear() {
    return new Date().getFullYear()
  }

  render() {
    if (this.props.location.pathname === '/') {
      return ''
    } else {
      return (
        <footer className="footer">
          <div className="container">
            <span className="text-muted">
              &copy; {this.getYear()} Sakura Films &amp; Productions by Melisa
              Kurtay. All rights reserved.
            </span>
          </div>
        </footer>
      )
    }
  }
}

export default withRouter(Footer)
