import firebase from "firebase/compat/app";

const config = {
  apiKey: "AIzaSyDvhBquRi1E5G_AI_Qul7p_lvLvuJtDRsk",
  authDomain: "sakura-fp.firebaseapp.com",
  databaseURL: "https://sakura-fp.firebaseio.com",
  projectId: "sakura-fp",
  storageBucket: "sakura-fp.appspot.com",
  messagingSenderId: "186805673972",
};

firebase.initializeApp(config);

export default firebase;
