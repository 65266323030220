import React, { Component } from 'react'

class About extends Component {
  render() {
    return (
      <div className="container content">
        <div className="row">
          <h2 className="title col-sm-6">About Me</h2>
          <div className="col-sm-6 text-right">
            <a
              href="http://www.imdb.com/name/nm1810276/"
              target="_blank"
              rel="noopener noreferrer"
              className="mr-2"
            >
              <i className="fab fa-imdb fa-2x imdb" />
            </a>
            <a
              href="https://www.linkedin.com/in/melisa-kurtay-91821439/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-linkedin fa-2x linkedin" />
            </a>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-sm-12 col-md-3">
            <img
              src="img/twd1.jpg"
              className="img-thumbnail img-fluid"
              alt="With Russel Crowe at The Water Diviner filming"
            />
          </div>
          <div className="col-sm-12 col-md-9">
            <p>
              Sakura Films and Productions is a production company owned by Ayse
              Melisa Kurtay based in London.
            </p>
            <p>
              I have been working both in production and direction side of
              behind the camera since 2001.
            </p>
            <p>
              After doing commercials, I have gradually moved to the film
              industry and worked as an Assistant Director in many feature
              films. Including major blockbusters like{' '}
              <a
                href="https://www.imdb.com/title/tt1074638/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Skyfall
              </a>
              {', '}
              <a
                href="https://www.imdb.com/title/tt3062096/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Inferno
              </a>
              {', '}
              <a
                href="https://www.imdb.com/title/tt1397280/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Taken 2
              </a>
              {', '}
              <a
                href="https://www.imdb.com/title/tt3007512/"
                target="_blank"
                rel="noopener noreferrer"
              >
                The Water Diviner
              </a>
              {' and '}
              <a
                href="https://www.imdb.com/title/tt0963178/"
                target="_blank"
                rel="noopener noreferrer"
              >
                The International
              </a>
              .
            </p>
            <p>
              You can access the list of films I have contributed to at{' '}
              <a
                href="http://www.imdb.com/name/nm1810276/"
                target="_blank"
                rel="noopener noreferrer"
              >
                IMDB
              </a>.
            </p>
            <p>
              I had the chance to work at many countries such as USA, Spain,
              Denmark, Sweden, Morocco, Norway, Bulgaria, India, Turkey. I am
              still a freelancer working in commercials as well as feature
              films.
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default About
