import React, { Component } from 'react'

class Contact extends Component {
  render() {
    return (
      <div className="container content">
        <div className="row">
          <h2 className="title col-sm-6">Contact Me</h2>
          <div className="col-sm-6 text-right">
            <a
              href="http://www.imdb.com/name/nm1810276/"
              target="_blank"
              rel="noopener noreferrer"
              className="mr-2"
            >
              <i className="fab fa-imdb fa-2x imdb" />
            </a>
            <a
              href="https://www.linkedin.com/in/melisa-kurtay-91821439/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-linkedin fa-2x linkedin" />
            </a>
          </div>
        </div>
        <hr />
        <p>
          For all inquiries, including requests for my CV, please do not
          hesitate to <a href="mailto:melisakurtay@gmail.com">email me</a>.
        </p>
      </div>
    )
  }
}

export default Contact
