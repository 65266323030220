import React, { Component } from 'react'
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'

import { Header, Home, About, Portfolio, Contact, Footer } from './'

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div>
          <Header />
          <Switch>
            <Route path="/about" component={About} />
            <Route path="/portfolio" component={Portfolio} />
            <Route path="/contact" component={Contact} />
            <Route exact path="/" component={Home} />
            <Redirect from="*" to="/" />
          </Switch>
          <Footer />
        </div>
      </BrowserRouter>
    )
  }
}

export default App
