import React, { Component } from 'react'
import { withRouter, Link, NavLink } from 'react-router-dom'

class Header extends Component {
  getPathClass() {
    const path = this.props.location.pathname
    return path === '/' ? 'home' : path.split('/')[1]
  }

  render() {
    return (
      <nav
        className={
          'navbar navbar-expand-lg navbar-custom navbar-expand-md ' +
          this.getPathClass()
        }
      >
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img
              src={
                this.getPathClass() === 'home'
                  ? '/img/sakura-white.svg'
                  : '/img/sakura.svg'
              }
              className="d-inline-block align-top"
              alt="Sakura Logo"
            />
            <div className="navbar-brand-text d-sm-none d-inline-block">
              Sakura Films
            </div>
            <div className="navbar-brand-text d-none d-sm-inline-block">
              SAKURA FILMS and PRODUCTIONS<br />
              <small>by Melisa Kurtay</small>
            </div>
          </Link>
          <button
            className="navbar-toggler custom-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#sakuraNavbar"
            aria-controls="sakuraNavbar"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>

          <div id="sakuraNavbar" className="collapse navbar-collapse">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <NavLink
                  exact
                  to="/about"
                  className="nav-link"
                  activeClassName="active"
                >
                  <i className="fal fa-user-circle fa-lg" /> About
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/portfolio"
                  className="nav-link"
                  activeClassName="active"
                >
                  <i className="fal fa-film-alt fa-lg" /> Portfolio
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  exact
                  to="/contact"
                  className="nav-link"
                  activeClassName="active"
                >
                  <i className="fal fa-envelope-open fa-lg" /> Contact
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    )
  }
}

export default withRouter(Header)
