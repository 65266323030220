import React, { Component } from 'react'
import _ from 'lodash'

import firebase from '../firebase.js'

function chunkMoviesForWindow(movies) {
  let chunkSize
  const windowSize = window.innerWidth

  if (windowSize >= 992) {
    chunkSize = 4
  } else if (windowSize < 992 && windowSize >= 768) {
    chunkSize = 2
  } else {
    chunkSize = 1
  }

  return _.chunk(movies, chunkSize)
}

class Portfolio extends Component {
  constructor() {
    super()
    this.state = {
      movies: []
    }
  }

  componentDidMount() {
    this.firebaseRef = firebase.database().ref('/movies')
    this.firebaseCallback = this.firebaseRef.on('value', snap => {
      this.setState({ movies: snap.val() })
    })
  }

  componentWillUnmount() {
    this.firebaseRef.off('value', this.firebaseCallback)
  }

  renderRows() {
    if (this.state.movies) {
      const chunkedMovies = chunkMoviesForWindow(this.state.movies)
      return chunkedMovies.map((movies, index) => {
        return (
          <div className="row pb-5" key={index}>
            {this.renderMovies(movies)}
          </div>
        )
      })
    } else {
      return <i className="fas fa-spinner fa-pulse" />
    }
  }

  renderMovies(movies) {
    const colWidth = 12 / movies.length
    return movies.map((movie, index) => {
      return (
        <div className={'card col-md-' + colWidth} key={index}>
          <img
            className="card-img-top"
            src={'/img/posters/' + movie.poster}
            alt={movie.name + ' Poster'}
          />
          <div className="card-body">
            <h5 className="card-title">
              <a
                href={'https://www.imdb.com/title/' + movie.imdbID + '/'}
                target="_blank"
                rel="noopener noreferrer"
              >
                {movie.name}
              </a>
            </h5>
            <p className="card-text small text-muted">{movie.title}</p>
            <a href={'/portfolio/' + movie.imdbID} className="btn btn-primary">
              Details
            </a>
          </div>
        </div>
      )
    })
  }

  render() {
    return (
      <div className="container content">
        <div className="gallery text-center">
          <div className="row">{this.renderRows()}</div>
        </div>
      </div>
    )
  }
}

export default Portfolio
