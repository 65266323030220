import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class Home extends Component {
  render() {
    return (
      <section className="hero" id="home">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6 offset-md-6">
              <p>
                From script to screen. For all your film{' '}
                <span className="amp">&amp;</span> production needs.
              </p>
              <Link to="/about" className="btn btn-dark btn-lg float-right">
                Learn more
              </Link>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Home
